export const Data = {
  bank: {
    balance: "$23500",
    transactions: [
      [
        "John wick",
        "recieve",
        "$2000",
        "No i can't do that",
        "2022-07-02",
        "hs71328gr9-bbfd-4b2d-9b5d-ab8dfbbd4bed",
      ],
      [
        "John wick",
        "send",
        "$2000",
        "Write some code of our app",
        "2022-07-01",
        "1eh37eh-bbfd-4b2d-9b5d-h82us23eh82",
      ],
      [
        "Choco ice cream store",
        "send",
        "$10",
        "",
        "2022-07-01",
        "3hr7e3w-bbfd-4b2d-9b5d-asd23rf3",
      ],
      [
        "Vercel",
        "send",
        "$100",
        "extended plan",
        "2022-06-27",
        "eh323y4-bbfd-4b2d-9b5d-23d4eqw24t5",
      ],
      [
        "Jame",
        "recieve",
        "$200",
        "web payment",
        "2022-06-21",
        "2h3eyr2-bbfd-4b2d-9b5d-sh7138d64g",
      ],
    ],
  },
};
