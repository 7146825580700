export const Data = {
  bank: {
    balance: "23500",
    transactions: [
      {
        id: 1,
        person: "John wick",
        type: "recieve",
        amount: "2000",
        message: "No i can't do that",
        date: "2022-07-02",
        recurring: "no",
      },
      {
        id: 2,
        person: "John wick",
        type: "send",
        amount: "2000",
        message: "Write some code of our app",
        date: "2022-07-01",
        recurring: "no",
      },
      {
        id: 3,
        person: "Choco ice cream store",
        type: "send",
        amount: "10",
        message: "",
        date: "2022-07-01",
        recurring: "no",
      },
      {
        id: 4,
        person: "Vercel",
        type: "send",
        amount: "100",
        message: "extended plan",
        date: "2022-06-27",
        recurring: "yes",
      },
      {
        id: 5,
        person: "Jame",
        type: "recieve",
        amount: "200",
        message: "web payment",
        date: "2022-06-21",
        recurring: "no",
      },
    ],
  },
};
